import { FC, Dispatch, SetStateAction } from 'react'
import usePrice from '@common/product/use-price'
import useComparePrice from '@common/product/use-compareprice'
import { useInput } from '@hooks'
import { Quantity } from '@components/ui'
import { Product, ProductVariant } from '@common/types/product'
import { ProductAddToCart, ProductOptions } from '@components/product'
import { SelectedOptions } from '../../helpers'
import s from './ProductViewAddToCart.module.scss'

// Todo: Add Variant Typing
interface Props {
  product: Product
  variant: ProductVariant | undefined
  selectedOptions: SelectedOptions
  setSelectedOptions: Dispatch<SetStateAction<SelectedOptions>>
  showBestPrice: false
  unadvertisedPrice: boolean
  beatCompetitorsPrice: boolean
  hasRequestInstall: boolean
  discountOff: string
}

const ProductViewAddToCart: FC<Props> = ({
  product,
  variant,
  selectedOptions,
  setSelectedOptions,
  showBestPrice,
  beatCompetitorsPrice,
  hasRequestInstall,
  discountOff,
}) => {
  const quantity = useInput(1)
  const { price } = usePrice({
    amount: variant?.price! * quantity.value,
    baseAmount: variant?.listPrice! * quantity.value,
    currencyCode: product.price.currencyCode,
  })
  const { compareprice } = useComparePrice({
    amount: variant?.listPrice! * quantity.value,
    baseAmount: variant?.listPrice! * quantity.value,
    currencyCode: product.price.currencyCode,
  })

  const unadvertisedPrice = product.specialPrice
  const isUnAdvertised = Boolean(unadvertisedPrice)

  //console.log('Ap ' + price)
  //console.log('Acp ' + compareprice)

  return (
    <div className={s['purchase-wrap']}>
      <h5 className={s['purchase-header']}>PURCHASE</h5>
      {(!product.notAvailableOnline ||
        product.notAvailableOnline?.showPrice) && (
        <>
          {product.options.length > 0 && (
            <ProductOptions
              options={product.options}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
            />
          )}
          <div className={s['price-wrap']}>
            <div className={s['qty-wrap']}>
              <Quantity
                value={quantity.value}
                increase={() => {
                  quantity.setValue(Number(quantity.value) + 1)
                }}
                decrease={() => {
                  quantity.setValue(Number(quantity.value) - 1)
                }}
                handleChange={quantity.onChange}
                active={variant?.availableForSale}
                max={variant?.quantityAvailable}
              />
            </div>
            <div className={s.price}>
              <span className={s.newprice}>{price}</span>
              {compareprice != '$0.00' && compareprice != '$NaN' && compareprice != price && (
                <span className={s.oldprice}>
                  <del>{compareprice}</del>
                </span>
              )}
            </div>
          </div>
        </>
      )}

      {showBestPrice && (
        <span className={s['toolow']}>
          Too low to advertise
        </span>
      )}
      <ProductAddToCart
        variant={variant}
        productId={product.id}
        productName={product.name}
        quantity={quantity.value}
        notAvailableOnline={product.notAvailableOnline}
        availableForSale={variant?.availableForSale}
        unadvertisedPrice={isUnAdvertised || false}
        discountOff={product.hasDiscountOff}
        beatCompetitorsPrice={product.hasBeatCompetitorsPrice}
        hasRequestInstall={product.hasRequestInstall}
        fullPage={true}
      />

      {/* <button className={cn(s['purchase-btn'], s['add-to-wishlist'])}>
        ADD TO WISHLIST
      </button> */}
    </div>
  )
}

export default ProductViewAddToCart
