import s from './ProductCollectionGrid.module.scss'
import { Grid, Column } from '@components/common'
import { Collection } from '@common/types/collection'
import { Link, Image } from '@components/ui'

interface Props {
  collections: Collection[]
}

const placeholderImage = '/static_images/ts-pool-supply-square-pool.jpg'

const ProductCollectionGrid = ({ collections }: Props) => {
  return (
    <div className={s.root}>
      <Grid smGutter={10} lgGutter={30} xsGutter={20}>
        {collections &&
          collections.map((collection) =>
            collection.category ? (
              <Column sm={6} lg={4} key={collection.id}>
                <div
                  style={{
                    borderTopColor: collection.color ?? '#FFFFFF',
                  }}
                  className={s['collection-card']}
                >
                  <h3 className={s.title}>
                    {collection.description
                      ? collection.description
                      : collection.title}
                  </h3>
                  <div className={s['collection-image-wrap']}>
                    <Link href={`collections/${collection.path}`}>
                      <Image
                        alt={collection.title ?? 'collection image'}
                        src={
                          collection.image ? collection.image : placeholderImage
                        }
                        layout="fill"
                        objectFit="scale-down"
                        sizes="320px"
                      />
                    </Link>
                  </div>
                  <Link
                    href={`collections/${collection.path}`}
                    className={s.btn}
                  >
                    <span>SHOP</span>
                    <span className={s['link-title']}> {collection.title}</span>
                  </Link>
                </div>
              </Column>
            ) : null
          )}
      </Grid>
    </div>
  )
}

export default ProductCollectionGrid
