import { FC } from 'react'
import { ExternalLink } from '@components/ui'
import s from './ProductViewManufacturer.module.scss'

interface Props {
  link: string
}

const ProductViewManufacturer: FC<Props> = ({ link }) => {
  return (
    <div className={s.manufacturer}>
      <ExternalLink
        className={s['manufacturer-link']}
        href={link}
        target="_blank"
      >
        VISIT MANUFACTURER SITE
      </ExternalLink>
      <p>
        Please visit manufacturer website regarding warranty inquiries. If you
        need help{' '}
        <a
          className={s['contact-link']}
          href="mailto:onlinesales@tspoolsupply.com?subject=TS Pool Supply Inquiry"
          target="_blank"
          rel="noopener noreferrer"
        >
          contact us.
        </a>
      </p>
    </div>
  )
}

export default ProductViewManufacturer
