import { FC } from 'react'
import DOMPurify from 'isomorphic-dompurify'
import { ProductSpec } from '@common/types/product'
import s from './ProductViewDescription.module.scss'

interface Props {
  description: string
  specs: ProductSpec[]
}

const ProductViewDescription: FC<Props> = ({ description, specs }) => {
  return (
    <div className={s['description-wrap']}>
      <div>
        <h5 className={s['description-header']}>DESCRIPTION</h5>
        <div
          className={s['description-text']}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(description),
          }}
        ></div>
      </div>
      {specs.length > 0 && (
        <div>
          <h5 className={s['description-header']}>SPECS</h5>
          <ul className={s.specs}>
            {specs.map(
              (spec) =>
                spec.value && (
                  <li className={s.spec} key={spec.label}>
                    <span>{spec.label}:</span>&nbsp;<span>{spec.value}</span>
                  </li>
                )
            )}
          </ul>
        </div>
      )}
    </div>
  )
}

export default ProductViewDescription
