import { FC } from 'react'
import { ProductOption } from '@common/types/product'
import { Select } from '@components/ui'
import { SelectedOptions } from '../helpers'
import s from './ProductOptions.module.scss'

interface Props {
  options: ProductOption[]
  selectedOptions: SelectedOptions
  setSelectedOptions: React.Dispatch<React.SetStateAction<SelectedOptions>>
}

const ProductOptions: FC<Props> = ({
  options,
  selectedOptions,
  setSelectedOptions,
}) => (
  <div>
    {options.map((opt) => (
      <div key={opt.id}>
        <div className={s['option-name']}>{opt.displayName}</div>
        <div className={s['option-select']}>
          <Select
            options={opt.values.map((val) => {
              return {
                label: val.label,
                value: val.label.toLowerCase(),
              }
            })}
            value={selectedOptions[opt.displayName.toLowerCase()] as string}
            onChange={(value) => {
              setSelectedOptions((selectedOptions) => {
                return {
                  ...selectedOptions,
                  [opt.displayName.toLowerCase()]: value as string,
                }
              })
            }}
          />
        </div>
      </div>
    ))}
  </div>
)

export default ProductOptions
