import React, { FC } from 'react'
import s from './ProductSlider.module.scss'
import { Slider } from '@components/common'
import { ProductCard } from '@components/product'
import type { Product } from '@common/types/product'
import { useMedia } from '@hooks'

interface Props {
  products: Product[]
  title?: string
}

const ProductSlider: FC<Props> = ({ products = [], title = '' }) => {
  const columns = useMedia(
    // Media queries
    ['(min-width: 992px)', '(min-width: 768px)'],
    // Column counts (relates to above media queries by array index)
    [3, 2],
    // Default column count
    1
  )

  return (
    <div className={s.root}>
      <div className={s.container}>
        {title && <h1 className={s.header}>{title}</h1>}
        <div className={s['slider-wrapper']}>
          <Slider columns={columns}>
            {products.map((product, index) => (
              <div key={product.id} className={s['card-wrapper']}>
                <ProductCard product={product} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default ProductSlider
